.chat-content {
  //   border: 3px solid yellow;
  height: 100%;
  display: flex;
  flex-direction: column;

  .chat-log-window {
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
    align-items: flex-end;
    // border: 3px solid red;
    background-image: url("../assets/chatback.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
    max-height: 70vh;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    .bubble {
      position: relative;
      max-width: 60%;
      padding: 10px;
      margin: 10px 20px 5px 20px;
      background: #ffffff;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      border-radius: 10px;
      word-break: break-all;
    }
    .bubble:after {
      left: 100%;
      top: 66%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-left-color: #ffffff;
      //   border-width: 10px;
      border-width: 8px 10px 5px;
      margin-top: -13px;
    }

    .another {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      .another-user {
        display: flex;
        align-items: center;
        width: 100%;

        .another-bubble {
          position: relative;
          max-width: 55%;
          padding: 10px;
          margin: 10px 20px 5px 20px;
          background: #ffffff;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          border-radius: 10px;
          word-break: break-all;
        }
        .another-bubble:after {
          right: 100%;
          top: 60%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-right-color: #ffffff;
          border-width: 8px 10px 8px;
          margin-top: -16.5px;
        }
      }
    }
  }
  .chat-log-window::-webkit-scrollbar {
    display: none;
  }
}
.chat-log-window.contain {
  background-size: contain;
}
.chat-log-window.cover {
  background-size: cover;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
